<template>
  <div class="career-guidance bg--color-01">
    <div class="container-new">
      <div class="career-guidance__container">
        <div
          class="career-guidance__title txt txt--36px txt--44px--m txt--height-44px txt--height-58px--m txt--color-04 txt--bold"
        >
          {{ $t('welcome.layer2Block.title') }}
        </div>

        <div class="career-guidance__info">
          <span
            class="txt txt--16px txt--height-20px txt--22px--m txt--height-30px--m txt--color-03"
            v-html="$t('welcome.layer2Block.desc')"
          />
        </div>

        <div class="career-guidance__image">
          <img
            :src="require('@/assets/img/welcome/layper2.svg')"
            width="627px"
            height="384px"
            alt="career-guide"
            loading="lazy"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ExcButton } from '@/components/common'
export default {
  components: {
    // ExcButton,
    // LabelSoon: () => import('@/components/ui-kit/labels/LabelSoon.vue'),
    // ButtonWithArrow: () =>
    //   import('@/components/welcome/elements/ButtonWithArrow.vue'),
  },
}
</script>

<style lang="scss" scoped>
.career-guidance {
  &__container {
    padding: toRem(20px) 0;
    display: grid;
    gap: toRem(24px);
    grid-template-areas:
      'title'
      'image'
      'info';

    @include bp(m) {
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: toRem(24px) toRem(48px);
      grid-template-areas:
        'title image'
        'info image';
    }

    @include bp(l) {
      gap: toRem(24px) toRem(64px);
      padding: toRem(60px) 0;
    }
  }

  &__title {
    grid-area: title;

    @include bp(m) {
      align-self: flex-end;
    }
  }

  &__info {
    grid-area: info;

    @include bp(m) {
      align-self: flex-start;
    }
  }

  &__image {
    grid-area: image;

    img {
      max-width: 100%;
      height: auto;
      width: 100%;
    }

    @include bp(0px, m) {
      text-align: center;

      img {
        max-width: toRem(380px);
      }
    }
  }

  &__button {
    @include bp(0px, m) {
      width: 100%;
    }
  }
}
</style>
