<template>
  <div class="cpex-ecosystem bg--color-02">
    <div class="container-wrapper bg--color-01">
      <div class="cpex-ecosystem__container container-new">
        <div class="cpex-ecosystem__title txt txt--36px txt--44px--m txt--height-44px txt--height-58px--m txt--color-04 txt--bold txt--center">
          {{ $t('welcome.ecosystemBlock.title') }}
        </div>

        <div class="cpex-ecosystem__description txt txt--16px txt--height-20px txt--22px--m txt--height-30px--m txt--color-03 mt-24 txt--center">
          {{ $t('welcome.ecosystemBlock.desc') }}
        </div>

        <img
          :src="require('@/assets/img/welcome/ecosystem.svg')"
          alt="border"
          loading="lazy"
          class="cpex-ecosystem__img mt-36"
        >

        <img
          :src="require('@/assets/img/welcome/ecosystem-mobile.svg')"
          alt="border"
          loading="lazy"
          class="cpex-ecosystem__img mobile mt-36"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPatexEcosystem',
}
</script>

<style lang="scss" scoped>
.cpex-ecosystem {
  &__img {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    @include bp(0, sm) {
      display: none;

      &.mobile {
        display: block;
      }
    }

    @include bp(sm) {
      display: block;

      &.mobile {
        display: none;
      }
    }

    @include bp(l) {
      max-width: 75%;
    }
  }
}
</style>
