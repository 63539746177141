<template>
  <div class="market-trend bg--color-01">
    <div class="container-wrapper bg--color-02">
      <div class="container-new txt--font-mulish">
        <!-- HEADER -->
        <div class="market-trend__header">
          <div class="market-trend__header--info flx flx--col">
            <span class="txt txt--44px txt--height-48px txt--color-04 txt--bold">
              {{ $t('welcome.marketTrendSection.title') }}
            </span>

            <span class="txt txt--22px txt--height-30px txt--color-03 pt-12">
              {{ $t('welcome.marketTrendSection.desc') }}
            </span>
          </div>

          <div class="market-trend__header--btn">
            <exc-button outline color="dark" :to="localePath('/market-info')">
              {{ $t('welcome.marketTrendSection.more') }}
            </exc-button>
          </div>
        </div>

        <!-- CONTENT (SLIDER) -->
        <div class="market-trend__content mt-40">
          <div
            v-for="index in 3"
            :key="`slider-row-${index}`"
            :class="['market-trend--animation-' + index]"
            class="market-trend__slider-row"
          >
            <div class="line">
              <market-trend-slider-item
                v-for="(item, itemIndex) in inTable || []"
                :key="itemIndex"
                :item="item"
              />
            </div>

            <div class="line second-slider-line">
              <market-trend-slider-item
                v-for="(item, itemIndex) in inTable || []"
                :key="itemIndex"
                :item="item"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ExcButton } from '@/components/common'

export default {
  name: 'MarketTrend',
  components: {
    ExcButton,
    MarketTrendSliderItem: () =>
      import('@/components/welcome/MarketTrend/MarketTrendSlideritem.vue'),
  },
  computed: {
    ...mapGetters({
      allRows: 'welcome/allRows',
    }),
    inTable () {
      return [
        ...this.allRows.filter(r => r.place === 'IN_TABLE'),
        ...this.allRows.filter(r => r.place === 'IN_TABLE'),
        ...this.allRows.filter(r => r.place === 'IN_TABLE'),
      ]
    },
  },
  created () {
    if (!process.client) return
    this.$store.dispatch('welcome/getFrontCurrencies')
  },
}
</script>

<style lang="scss" scoped>
.market-trend {
  // max-width: 100vw;
  overflow-x: hidden;

  &__header {
    display: flex;
    flex-direction: column;
    gap: toRem(12px);

    @include bp(m) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      &--info {
        max-width: 45%;
      }
    }

    &--btn {
      max-width: fit-content;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: toRem(20px);
  }

  &__coin-info {
    display: grid;
    grid-template-columns: toRem(40px) 1fr;
    gap: toRem(8px);
    align-items: center;
  }

  &__slider-row {
    display: flex;
    position: relative;
    flex-direction: row;
    // gap: toRem(20px);
    flex: 1 1 0%;
    position: relative;
    flex-wrap: nowrap;

    .line {
      display: flex;
      flex-direction: row;
      gap: toRem(20px);
    }
  }

  &__slider-item {
    min-width: toRem(260px);
    display: flex;
    flex-direction: row;
    gap: toRem(14px);
    justify-content: space-between;
    align-items: center;

    transform: translate3d(0, 0, 0);
    position: absolute;

    padding: toRem(20px);
    border-radius: toRem(10px);

    box-shadow: 0px 1px 20px 0px #1618220f;
  }

  &--animation-1 {
    .line {
      animation: slideRight 240s linear infinite;
    }
    .seconds-slider-line {
      animation-delay: 120s;
    }
  }

  &--animation-2 {
    .line {
      animation: slideLeft 160s linear infinite;
    }
    .seconds-slider-line {
      animation-delay: 80s;
    }
  }

  &--animation-3 {
    .line {
      animation: slideRight 180s linear infinite;
    }
    .seconds-slider-line {
      animation-delay: 90s;
    }
  }

  @keyframes slideRight {
    0% {
      transform: translateX(-5020px);
      // transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translateX(0px);
      // transform: translate3d(0, 0, 0);
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translate3d(-250px, 0, 0);
    }
    100% {
      transform: translate3d(-5270px, 0, 0);
    }
  }
}
</style>
