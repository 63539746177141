<template>
  <div class="demo-trading bg--color-01">
    <div class="container-new">
      <div class="demo-trading__container">
        <div
          class="demo-trading__title txt txt--36px txt--44px--m txt--height-44px txt--height-58px--m txt--color-04 txt--bold"
        >
          {{ $t('welcome.demoTrading.title') }}
        </div>

        <div class="demo-trading__info">
          <span
            class="txt txt--16px txt--height-20px txt--22px--m txt--height-30px--m txt--color-03"
            v-html="$t('welcome.demoTrading.text')"
          />

          <!-- <button-with-arrow
            size="l"
            class="demo-trading__button no-mobile-only mt-24 mt-32--m"
            :to="localePath('/demo-exchange')"
          >
            {{ $t('welcome.demoTrading.tradeNowBtn') }}
          </button-with-arrow> -->
          <exc-button
            class="demo-trading__button no-mobile-only mt-24 mt-32--m"
            soon
            color="dark"
            size="l"
          />
        </div>

        <div class="demo-trading__btn-block mobile-only">
          <!-- <button-with-arrow
            size="l"
            class="demo-trading__button"
            :to="localePath('/demo-exchange')"
          >
            {{ $t('welcome.demoTrading.tradeNowBtn') }}
          </button-with-arrow> -->
          <exc-button class="demo-trading__button" soon color="dark" size="l" />
        </div>

        <div class="demo-trading__image">
          <img
            :src="require('@/assets/img/welcome/macbook.png')"
            width="48px"
            height="48px"
            loading="lazy"
            alt="demo-trading-preview"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExcButton } from '@/components/common'
export default {
  components: {
    ExcButton,
    // LabelSoon: () => import('@/components/ui-kit/labels/LabelSoon.vue'),
    // ButtonWithArrow: () =>
    //   import('@/components/welcome/elements/ButtonWithArrow.vue'),
  },
}
</script>

<style lang="scss" scoped>
.demo-trading {
  .mobile-only {
    @include bp(m) {
      display: none;
    }
  }

  .no-mobile-only {
    @include bp(0px, m) {
      display: none;
    }
  }

  &__container {
    padding: toRem(20px) 0;
    display: grid;
    gap: toRem(24px);
    grid-template-areas:
      'title'
      'info'
      'image'
      'btn';

    @include bp(m) {
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: toRem(24px) toRem(48px);
      grid-template-areas:
        'title image'
        'info image';
    }

    @include bp(l) {
      gap: toRem(24px) toRem(64px);
      padding: toRem(60px) 0;
    }
  }

  &__title {
    grid-area: title;

    @include bp(m) {
      align-self: flex-end;
    }
  }

  &__info {
    grid-area: info;

    @include bp(m) {
      align-self: flex-start;
    }
  }

  &__image {
    grid-area: image;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    @include bp(0px, m) {
      text-align: center;

      img {
        max-width: toRem(380px);
      }
    }
  }

  &__button {
    width: fit-content;

    @include bp(0px, m) {
      width: 100%;
    }
  }

  &__btn-block {
    grid-area: btn;
  }
}
</style>
