<template>
  <div class="Welcome bg--color-02">
    <top-section />
    <c-patex-ecosystem />
    <market-trend />
    <why-latin-america />
    <welcome-network />
    <welcome-layer-2 />
    <welcome-cpex-in-network />
    <subscribe-block bg-class="bg--color-01" default-paddings />
    <improvment-block />
    <crypto-academy />
    <career-guidance />
    <EasyStepsVids />
    <our-products />
    <specializations />
    <how-to-deploy />
    <demo-trading />
    <faq-block bg-content-color-class="bg--color-01" />
    <subscribe-block />
  </div>
</template>

<script>
import { getDefaultHeadData } from '@/lib/utils'

export default {
  name: 'Index',
  components: {
    TopSection: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/TopSection_new.vue'
      ),
    CPatexEcosystem: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/CPatexEcosystem.vue'
      ),
    MarketTrend: () =>
      import(/* webpackMode: "eager" */ '@/components/welcome/MarketTrend.vue'),
    EasyStepsVids: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/EasyStepsVids.vue'
      ),
    OurProducts: () =>
      import(/* webpackMode: "eager" */ '@/components/welcome/OurProducts.vue'),
    WelcomeNetwork: () =>
      import(/* webpackMode: "eager" */ '@/components/welcome/WelcomeNetwork.vue'),
    WelcomeLayer2: () =>
      import(/* webpackMode: "eager" */ '@/components/welcome/WelcomeLayer2.vue'),
    WelcomeCpexInNetwork: () =>
      import(/* webpackMode: "eager" */ '@/components/welcome/WelcomeCpexInNetwork.vue'),
    ImprovmentBlock: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/ImprovmentBlock.vue'
      ),
    WhyLatinAmerica: () => import('@/components/welcome/WhyLatinAmerica.vue'),
    CryptoAcademy: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/CryptoAcademy.vue'
      ),
    CareerGuidance: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/CareerGuidance.vue'
      ),
    Specializations: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/Specializations.vue'
      ),
    HowToDeploy: () =>
      import(/* webpackMode: "eager" */ '@/components/welcome/HowToDeploy.vue'),
    DemoTrading: () =>
      import(/* webpackMode: "eager" */ '@/components/welcome/DemoTrading.vue'),
    FaqBlock: () =>
      import(/* webpackMode: "eager" */ '@/components/welcome/FaqBlock.vue'),
    SubscribeBlock: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/SubscribeBlock.vue'
      ),
  },
  head () {
    return getDefaultHeadData({
      title: this.$t('meta.index.title'),
      description: this.$t('meta.index.description'),
      img: '/image/meta/og-index.png',
      locale: this.$i18n.locale,
    })
  },
  beforeMount () {
    this.$api.$marketSubscribe()
  },
  beforeDestroy () {
    this.$api.$marketUnsubscribe()
  },
}
</script>

<style scoped lang="scss">
.Welcome {
  overflow: initial;
  // overflow-x: hidden;

  &__payment-section-container {
    display: none;

    @include bp(m) {
      display: block;
    }
  }
}
</style>
