<template>
  <div class="easy-steps-vids bg--color-01">
    <div class="container-wrapper bg--color-10">
      <div class="easy-steps-vids__container container-new">
        <span
          class="easy-steps-vids__title txt--bold txt--36px txt--44px--l txt--height-44px txt--height-58px--l txt--color-04 mb-0"
        >
          {{ $t('welcome.easyStepsSection.title') }}
        </span>

        <ul class="easy-steps-vids__list">
          <li
            v-for="(item, i) in steps"
            :key="steps[item]"
            class="easy-steps-vids__item"
          >
            <div class="easy-steps-vids__item-id">
              <span
                class="txt--weight-600 txt--18px txt--height-21px txt--color-01"
              >
                {{ ++i }}
              </span>
            </div>
            <div class="easy-steps-vids__item-info">
              <span class="txt--bold txt--22px txt--height-30px txt--color-04">
                {{ item.title }}
              </span>
              <p
                class="txt--weight-400 txt--16px txt--height-20px txt--color-04 m-0"
              >
                {{ item.desc }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EasyStepsVids',
  components: {},
  computed: {
    steps () {
      return [
        {
          title: this.$t('welcome.easyStepsSection.steps[0].title'),
          desc: this.$t('welcome.easyStepsSection.steps[0].desc'),
        },
        {
          title: this.$t('welcome.easyStepsSection.steps[1].title'),
          desc: this.$t('welcome.easyStepsSection.steps[1].desc'),
        },
        {
          title: this.$t('welcome.easyStepsSection.steps[2].title'),
          desc: this.$t('welcome.easyStepsSection.steps[2].desc'),
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.easy-steps-vids {
  &__container {
    display: grid;
    gap: toRem(16px);

    @include bp(l) {
      grid-template-columns: 1fr 2fr;
      gap: toRem(20px);
    }
  }

  &__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: toRem(32px);

    @include bp(l) {
      flex-direction: column;
      justify-content: space-between;
      gap: toRem(20px);
      height: max-content;
      margin: auto 0;
    }

    @include bp($w-media-desktop) {
      flex-direction: row;
    }
  }

  &__item {
    display: flex;
    gap: toRem(10px);
  }

  &__item-id {
    background: #9692ef;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-width: toRem(28px);
    max-width: toRem(28px);
    min-height: toRem(28px);
    max-height: toRem(28px);
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    gap: toRem(6px);

    @include bp(l) {
      gap: toRem(8px);
    }
  }
}
</style>
