<template>
  <div class="easy-steps-vids bg--color-01">
    <div class="container-wrapper bg--color-01">
      <div class="easy-steps-vids__container container-new">
        <span
          class="easy-steps-vids__title txt--bold txt--36px txt--44px--m txt--height-44px txt--height-58px--l txt--color-04 mb-0"
          v-html="$t('welcome.cpexInNetworkBlock.title')"
        />

        <p
          class="txt--weight-400 txt--16px txt--22px--m txt--height-20px txt--height-30px--m txt--color-03 mt-18"
          v-html="$t('welcome.cpexInNetworkBlock.desc')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EasyStepsVids',
  components: {},
  computed: {
    steps () {
      return [
        {
          title: this.$t('welcome.easyStepsSection.steps[0].title'),
          desc: this.$t('welcome.easyStepsSection.steps[0].desc'),
        },
        {
          title: this.$t('welcome.easyStepsSection.steps[1].title'),
          desc: this.$t('welcome.easyStepsSection.steps[1].desc'),
        },
        {
          title: this.$t('welcome.easyStepsSection.steps[2].title'),
          desc: this.$t('welcome.easyStepsSection.steps[2].desc'),
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.easy-steps-vids {
  .container-wrapper {
    @include bp(l) {
      padding-top: 0px !important;
    }
  }

  &__container {
    display: grid;
    gap: toRem(16px);

    @include bp(l) {
      grid-template-columns: 2fr 3fr;
      align-items: center;
      gap: toRem(40px);
    }
  }
}
</style>
