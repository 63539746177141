<template>
  <div class="specializations-block bg--color-01">
    <div class="container-wrapper bg--color-01">
      <div class="specializations-block__container">
        <div class="specializations-block__grid container-new">
          <div class="specializations-block__info-item">
            <span
              class="txt txt--36px txt--height-44px txt--44px--m txt--height-58px--m txt--color-04 txt--bold"
            >
              {{ $t('welcome.specializations.title') }}
            </span>

            <span
              class="txt txt--16px txt--height-20px txt--22px--m txt--height-30px--m txt--color-04"
            >
              {{ $t('welcome.specializations.text') }}
            </span>
          </div>

          <div class="specializations-block__btn">
            <button-with-arrow
              size="l"
              class="specializations-block__button"
              :to="localePath('/user/ticket')"
            >
              {{ $t('welcome.specializations.getSpecializationsBtn') }}
            </button-with-arrow>
            <!-- <exc-button
              class="specializations-block__button"
              soon
              color="dark"
              size="l"
            /> -->
          </div>

          <div class="specializations-block__items">
            <span
              class="txt txt--22px txt--height-30px txt--26px--m txt--height-34px--m txt--color-04 txt--weight-500"
            >
              {{ $t('welcome.specializations.listOfItemTitle') }}
            </span>

            <div class="specializations-block__items-list">
              <div
                v-for="(item, index) in items"
                :key="index"
                class="specializations-block__items-item bg--color-02"
              >
                <img :src="item.image" :alt="item.text" loading="lazy">

                <span
                  class="txt txt--22px txt--height-30px txt--26px--m txt--height-34px--m txt--color-04 txt--weight-500"
                >
                  {{ item.text }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ExcButton } from '@/components/common'
export default {
  components: {
    // ExcButton,
    ButtonWithArrow: () =>
      import('@/components/welcome/elements/ButtonWithArrow.vue'),
  },
  computed: {
    items () {
      return [
        {
          image: require('@/assets/img/welcome/specs/launchpad.svg'),
          text: this.$t('welcome.specializations.items.0'),
        },
        {
          image: require('@/assets/img/welcome/specs/coin.svg'),
          text: this.$t('welcome.specializations.items.1'),
        },
        {
          image: require('@/assets/img/welcome/specs/puzzles.svg'),
          text: this.$t('welcome.specializations.items.2'),
        },
        {
          image: require('@/assets/img/welcome/specs/chart.svg'),
          text: this.$t('welcome.specializations.items.3'),
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.specializations-block {
  &__info-item {
    grid-area: info;
    display: flex;
    flex-direction: column;
    gap: toRem(18px);
  }

  &__btn {
    grid-area: button;

    @include bp(m) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__items {
    grid-area: items;
    display: flex;
    flex-direction: column;
    gap: toRem(24px);
  }

  &__items-list {
    grid-area: items;
    display: grid;
    grid-template-columns: 1fr;
    gap: toRem(12px);

    @include bp(m) {
      grid-template-columns: repeat(2, 1fr);
      gap: toRem(24px);
    }

    @include bp(l) {
      grid-template-columns: repeat(4, 1fr);
      gap: toRem(24px);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'info'
      'items'
      'button';
    gap: toRem(24px);

    @include bp(m) {
      grid-template-columns: 2fr 1fr;
      gap: toRem(40px) toRem(32px);
      grid-template-areas:
        'info button'
        'items items';
    }
  }

  &__items-item {
    display: grid;
    grid-template-columns: toRem(48px) 1fr;
    align-items: center;
    gap: toRem(12px);
    padding: toRem(12px);
    border-radius: toRem(20px);

    @include bp(0px, m) {
      img {
        max-width: toRem(48px);
      }
    }

    @include bp(m) {
      grid-template-columns: 1fr;
      grid-template-rows: toRem(100px) 1fr;
      gap: toRem(12px);
      padding: toRem(24px);
      border-radius: toRem(24px);
      text-align: center;

      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__button {
    @include bp(0px, m) {
      width: 100%;
    }
  }
}
</style>
