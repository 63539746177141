<template>
  <div class="improvment">
    <div class="container-wrapper bg--color-01">
      <div class="improvment__container container-new">
        <span
          class="txt--bold txt--36px txt--52px--m txt--height-44px txt--height-64px--m txt--color-04"
        >
          {{ $t('welcome.networkBlock.title') }}
        </span>
        <p
          class="txt--weight-400 txt--16px txt--22px--m txt--height-20px txt--height-30px--m txt--color-03 mt-18"
          v-html="$t('welcome.networkBlock.desc')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>

<style lang="scss" scoped>
.improvment {
  background-color: #a5d4ff;

  .container-wrapper,
  .container-new {
    @include bp(l) {
      padding-bottom: 0px !important;
    }
  }

  &__container {
    // padding-top: toRem(20px);
    // padding-bottom: toRem(48px);

    @include bp(l) {
      // padding-top: toRem(60px);
      // padding-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1000px;

      p {
        text-align: center;
      }
    }
  }
}
</style>
